@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,300&family=Poppins&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
  background: none;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input {
  appearance: none;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon-arrow-right {
  width: 1.91em;
  height: 1em;
  fill: #FFF;
}

.icon-dribbble {
  width: 1em;
  height: 1em;
  fill: #FFF;
}

.icon-facebook {
  width: 0.63em;
  height: 1em;
  fill: #FFF;
}

.icon-instagram {
  width: 1em;
  height: 1em;
  fill: #FFF;
}

.icon-mail {
  width: 1.2em;
  height: 1em;
  fill: #FFF;
}

.icon-twitter {
  width: 1.14em;
  height: 1em;
  fill: #FFF;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

html.no-scroll {
  overflow: hidden;
}

body {
  min-width: 375px;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  line-height: 1.35;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #868E9C;
}

@media only screen and (max-width: 1199px) {
  body {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 16px;
  }
}

body.no-scroll {
  overflow: hidden;
}

.page {
  overflow: hidden;
}

a.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn {
  min-width: 270px;
  height: 48px;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .btn {
    height: 42px;
  }
}

.btn__pic, .btn__text {
  display: inline-block;
  vertical-align: middle;
}

.btn__pic {
  margin-right: 17px;
}

@media only screen and (max-width: 767px) {
  .btn__pic {
    max-height: 24px;
  }
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
}

.btn:active {
  transform: translateY(2px);
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.15);
}

.btn_blue {
  background: #373981;
  color: #fff;
}

.btn_white {
  background: #fff;
  color: #8B5DF2;
}

.center {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 48px 0;
}

@media only screen and (max-width: 1199px) {
  .header {
    padding: 36px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    padding: 26px 0;
  }
}

.header__center {
  display: flex;
  align-items: center;
  max-width: 1790px;
}

.header__logo {
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .header__logo {
    margin-top: -5px;
  }
}

@media only screen and (max-width: 1023px) {
  .header__logo {
    margin: 0 auto 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__logo {
    position: relative;
    z-index: 20;
  }
}

.header__logo .header__pic {
  width: 174px;
}

@media only screen and (max-width: 767px) {
  .header__logo .header__pic {
    width: 160px;
  }
}

.header__wrap {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

@media only screen and (max-width: 1023px) {
  .header__wrap {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 350px;
    min-height: 100vh;
    padding: 100px 50px 50px;
    background: #373981;
    transform: translateX(100%);
    transition: transform .25s;
  }
  .header__wrap.visible {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .header__wrap {
    width: 100%;
    padding-top: 120px;
    background: rgba(55, 57, 129, 0.95);
    text-align: center;
  }
}

.header__nav {
  display: flex;
  margin: 0 auto;
}

@media only screen and (max-width: 1023px) {
  .header__nav {
    flex-direction: column;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .header__nav {
    align-items: center;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 474px) {
  .header__nav {
    margin-bottom: 40px;
  }
}

.header__link {
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  opacity: .4;
  transition: opacity .25s;
}

@media only screen and (max-width: 1199px) {
  .header__link {
    font-size: 15px;
    opacity: .7;
  }
}

@media only screen and (max-width: 1023px) {
  .header__link {
    font-size: 20px;
    font-weight: 700;
    opacity: 1;
  }
}

.header__link:hover {
  opacity: 1;
}

.header__link:not(:last-child) {
  margin-right: 64px;
}

@media only screen and (max-width: 1365px) {
  .header__link:not(:last-child) {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .header__link:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .header__link:not(:last-child) {
    margin: 0 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .header__link:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 474px) {
  .header__link:not(:last-child) {
    margin-bottom: 40px;
  }
}

.header__contact {
  display: inline-flex;
  align-items: center;
  margin-left: 35px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: opacity .25s;
}

@media only screen and (max-width: 1199px) {
  .header__contact {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .header__contact {
    display: inline-block;
    font-size: 20px;
  }
}

.header__contact .icon {
  margin-right: 15px;
  font-size: 20px;
  fill: #fff;
}

@media only screen and (max-width: 1023px) {
  .header__contact .icon {
    display: none;
  }
}

.header__contact:hover {
  opacity: .75;
}

.header__burger {
  display: none;
  position: relative;
  z-index: 12;
  width: 30px;
  height: 30px;
  background: none;
  font-size: 0;
  transition: opacity .25s;
}

@media only screen and (max-width: 1023px) {
  .header__burger {
    display: inline-block;
  }
}

.header__burger:before, .header__burger:after {
  content: "";
  display: inline-block;
  width: 30px;
  height: 3px;
  background: #fff;
  border-radius: 2px;
  transition: transform .25s;
}

@media only screen and (max-width: 767px) {
  .header__burger:before, .header__burger:after {
    width: 22px;
    height: 2px;
  }
}

.header__burger:before {
  margin-bottom: 4px;
}

@media only screen and (max-width: 767px) {
  .header__burger:before {
    margin-bottom: 3px;
  }
}

.header__burger:after {
  margin-top: 4px;
}

@media only screen and (max-width: 767px) {
  .header__burger:after {
    margin-top: 3px;
  }
}

.header__burger:hover {
  opacity: .85;
}

.header__burger.active:before {
  transform: translateY(5.5px) rotate(45deg);
}

@media only screen and (max-width: 767px) {
  .header__burger.active:before {
    transform: translateY(4px) rotate(45deg);
  }
}

.header__burger.active:after {
  transform: translateY(-5.5px) rotate(-45deg);
}

@media only screen and (max-width: 767px) {
  .header__burger.active:after {
    transform: translateY(-4px) rotate(-45deg);
  }
}

.header__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

@media only screen and (max-width: 1023px) {
  .header__bg.show {
    visibility: visible;
    opacity: 1;
  }
}

.main {
  position: relative;
  padding: 263px 0 128px;
  background-image: linear-gradient(46deg, #7040DC 0%, #C37BFB 53%, #F99F1E 100%);
  color: #fff;
}

@media only screen and (max-width: 1439px) {
  .main {
    padding-top: 240px;
  }
}

@media only screen and (max-width: 1199px) {
  .main {
    padding-top: 210px;
  }
}

@media only screen and (max-width: 767px) {
  .main {
    padding: 120px 0 450px;
  }
}

.main__center {
  position: relative;
  z-index: 5;
}

.main__title {
  max-width: 470px;
  margin-bottom: 25px;
}

.main__text {
  max-width: 470px;
  margin-bottom: 64px;
  opacity: .7;
}

@media only screen and (max-width: 767px) {
  .main__text {
    margin-bottom: 34px;
  }
}

.main__btns {
  display: flex;
}

@media only screen and (max-width: 666px) {
  .main__btns {
    display: block;
  }
}

.main__btn:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (max-width: 666px) {
  .main__btn:not(:last-child) {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .main__btn .btn {
    min-width: 240px;
  }
}

@media only screen and (max-width: 666px) {
  .main__btn .btn {
    min-width: 100%;
  }
}

.main__preview {
  position: absolute;
  right: calc(50% - 784px);
  bottom: -145px;
  z-index: 4;
  font-size: 0;
  pointer-events: none;
  opacity: 0;
  animation: phone 1.2s .7s forwards;
}

@media only screen and (max-width: 1365px) {
  .main__preview {
    right: calc(50% - 670px);
    bottom: -117px;
  }
}

@media only screen and (max-width: 1199px) {
  .main__preview {
    right: calc(50% - 620px);
  }
}

@media only screen and (max-width: 1023px) {
  .main__preview {
    right: calc(50% - 575px);
    bottom: -52px;
  }
}

@media only screen and (max-width: 767px) {
  .main__preview {
    right: 20%;
    bottom: -120px;
  }
}

@media only screen and (max-width: 666px) {
  .main__preview {
    right: -20%;
  }
}

@media only screen and (max-width: 1365px) {
  .main__preview .main__pic {
    width: 650px;
  }
}

@media only screen and (max-width: 1199px) {
  .main__preview .main__pic {
    width: 600px;
  }
}

@media only screen and (max-width: 1023px) {
  .main__preview .main__pic {
    width: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .main__preview .main__pic {
    width: 450px;
  }
}

.main__circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: .3;
}

.main__circle {
  position: absolute;
  top: -160px;
  border-radius: 50%;
  background-image: radial-gradient(50% 99%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0.3) 99%);
  animation: circle1 1s forwards;
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .main__circle {
    top: -70px;
  }
}

.main__circle:first-child {
  right: calc(50% - 1080px);
  z-index: 3;
  width: 800px;
  height: 800px;
  animation-delay: .3s;
}

@media only screen and (max-width: 1199px) {
  .main__circle:first-child {
    right: calc(50% - 980px);
    width: 700px;
    height: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .main__circle:first-child {
    right: -125px;
    width: 250px;
    height: 250px;
  }
}

.main__circle:nth-child(2) {
  right: calc(50% - 1180px);
  z-index: 2;
  width: 1000px;
  height: 1000px;
  animation-delay: .6s;
}

@media only screen and (max-width: 1199px) {
  .main__circle:nth-child(2) {
    right: calc(50% - 1080px);
    width: 900px;
    height: 900px;
  }
}

@media only screen and (max-width: 767px) {
  .main__circle:nth-child(2) {
    right: -150px;
    width: 300px;
    height: 300px;
  }
}

.main__circle:nth-child(3) {
  right: calc(50% - 1280px);
  z-index: 1;
  width: 1200px;
  height: 1200px;
  animation-delay: .9s;
}

@media only screen and (max-width: 1199px) {
  .main__circle:nth-child(3) {
    right: calc(50% - 1180px);
    width: 1100px;
    height: 1100px;
  }
}

@media only screen and (max-width: 767px) {
  .main__circle:nth-child(3) {
    right: -175px;
    width: 350px;
    height: 350px;
  }
}

@keyframes phone {
  0% {
    transform: translateX(20%) rotate(20deg);
    opacity: 0;
  }
  30% {
    opacity: .7;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateX(0) rotate(0);
    opacity: 1;
  }
}

@keyframes circle1 {
  0% {
    transform: translate(10%, -20%);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.clients {
  padding: 64px 0;
  background: #373981;
}

@media only screen and (max-width: 1023px) {
  .clients {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .clients {
    padding: 96px 0;
  }
}

.clients__title {
  margin-bottom: 39px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 1.375;
  letter-spacing: 1px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .clients__title {
    font-size: 22px;
  }
}

.clients__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -25px;
}

.clients__logo {
  margin: 25px 25px 0;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .clients__logo {
    flex: 0 0 50%;
    width: 50%;
    margin: 25px 0 0;
    padding: 0 15px;
    text-align: center;
  }
}

.clients__logo[data-aos="animation-custom"] {
  transform: translateY(30px) scaleY(2);
  opacity: 0;
  transition-property: transform, opacity;
}

.clients__logo[data-aos="animation-custom"].aos-animate {
  transform: translateY(0) scaleY(1);
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .clients__pic {
    max-width: 100%;
  }
}

.title {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  line-height: 1.25;
  letter-spacing: 1px;
}

@media only screen and (max-width: 1199px) {
  .title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .title {
    font-size: 26px;
  }
}

.title_big {
  font-size: 60px;
  line-height: 1.16667;
}

@media only screen and (max-width: 1199px) {
  .title_big {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .title_big {
    font-size: 34px;
  }
}

.details {
  padding: 178px 0;
  background-image: linear-gradient(180deg, #373881 0%, #8B84E7 51%, #FFA350 100%);
}

@media only screen and (max-width: 1365px) {
  .details {
    padding: 130px 0;
  }
}

@media only screen and (max-width: 1199px) {
  .details {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .details {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 1199px) {
  .details__center {
    max-width: 1024px;
  }
}

@media only screen and (max-width: 1023px) {
  .details__center {
    max-width: 768px;
  }
}

@media only screen and (max-width: 767px) {
  .details__center {
    max-width: 375px;
  }
}

.details__item {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .details__item {
    flex-direction: column;
  }
}

.details__item:nth-child(2n+1) .details__preview {
  margin-right: auto;
  margin-left: 200px;
}

@media only screen and (max-width: 1199px) {
  .details__item:nth-child(2n+1) .details__preview {
    margin-left: 150px;
  }
}

@media only screen and (max-width: 1023px) {
  .details__item:nth-child(2n+1) .details__preview {
    margin: 0 auto 50px 250px;
  }
}

@media only screen and (max-width: 767px) {
  .details__item:nth-child(2n+1) .details__preview {
    margin: 0 0 55px auto;
  }
}

@media only screen and (max-width: 1023px) {
  .details__item:nth-child(2n) {
    flex-direction: column-reverse;
  }
}

.details__item:nth-child(2n) .details__preview {
  margin-left: auto;
}

@media only screen and (max-width: 1023px) {
  .details__item:nth-child(2n) .details__preview {
    margin: 0 auto 55px 250px;
  }
}

@media only screen and (max-width: 767px) {
  .details__item:nth-child(2n) .details__preview {
    margin: 0 0 55px auto;
  }
}

.details__item:not(:last-child) {
  margin-bottom: 228px;
}

@media only screen and (max-width: 1365px) {
  .details__item:not(:last-child) {
    margin-bottom: 180px;
  }
}

@media only screen and (max-width: 1199px) {
  .details__item:not(:last-child) {
    margin-bottom: 140px;
  }
}

@media only screen and (max-width: 1023px) {
  .details__item:not(:last-child) {
    margin-bottom: 120px;
  }
}

.details__preview {
  position: relative;
  text-align: right;
  font-size: 0;
}

.details__preview:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 470px;
  height: 470px;
  border-radius: 50%;
  border: 0.5px solid rgba(255, 255, 255, 0.4);
}

@media only screen and (max-width: 1199px) {
  .details__preview:before {
    width: 390px;
    height: 390px;
  }
}

@media only screen and (max-width: 767px) {
  .details__preview:before {
    width: 270px;
    height: 270px;
  }
}

.details__photo {
  display: inline-block;
  position: relative;
  z-index: 2;
  width: 370px;
  height: 370px;
  border-radius: 50%;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 1199px) {
  .details__photo {
    width: 320px;
    height: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .details__photo {
    width: 220px;
    height: 220px;
  }
}

.details__bg {
  position: absolute;
  bottom: 33px;
  right: 300px;
  width: 270px;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .details__bg {
    right: 250px;
    width: 230px;
  }
}

@media only screen and (max-width: 767px) {
  .details__bg {
    right: 175px;
    width: 150px;
  }
}

.details__bg[data-aos="animation-custom"] {
  transform: translateX(50px);
  opacity: 0;
  transition-property: transform, opacity;
}

.details__bg[data-aos="animation-custom"].aos-animate {
  transform: translateX(0);
  opacity: 1;
}

.details__pic {
  width: 100%;
  border-radius: 50%;
}

.details__number {
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  padding-top: 5px;
  overflow: hidden;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  color: #8B5DF2;
}

@media only screen and (max-width: 1199px) {
  .details__number {
    top: -40px;
    width: 90px;
    height: 90px;
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .details__number {
    top: -25px;
    width: 64px;
    height: 64px;
    font-size: 28px;
  }
}

.details__number[data-aos="animation-custom"] {
  transform: translateY(50px) scaleY(1.1);
  transition-property: transform, opacity;
  opacity: 0;
}

.details__number[data-aos="animation-custom"].aos-animate {
  transform: translateY(0) scaleY(1);
  opacity: 1;
}

.details__wrap {
  width: 470px;
  padding-top: 8px;
  color: #fff;
}

@media only screen and (max-width: 1199px) {
  .details__wrap {
    width: 410px;
  }
}

@media only screen and (max-width: 1023px) {
  .details__wrap {
    width: 100%;
  }
}

.details__title {
  margin-bottom: 20px;
}

.details__text {
  margin-bottom: 42px;
  opacity: 0.7;
}

@media only screen and (max-width: 1199px) {
  .details__text {
    margin-bottom: 32px;
  }
}

.details__more {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
}

.details__more .icon {
  margin-left: 8px;
  font-size: 10px;
  fill: #fff;
  transition: transform .25s;
}

.details__more:hover .icon {
  transform: translateX(3px);
}

.features {
  padding: 141px 0 128px;
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .features {
    padding: 100px 0;
  }
}

.features__title {
  margin-bottom: 5px;
  color: #373981;
}

@media only screen and (max-width: 767px) {
  .features__title {
    margin-bottom: 16px;
  }
}

.features__info {
  margin-bottom: 96px;
}

@media only screen and (max-width: 1199px) {
  .features__info {
    margin-bottom: 76px;
  }
}

@media only screen and (max-width: 767px) {
  .features__info {
    margin-bottom: 64px;
  }
}

.features__list {
  display: flex;
  flex-wrap: wrap;
  margin: -96px -15px 0;
}

@media only screen and (max-width: 1199px) {
  .features__list {
    margin-top: -76px;
  }
}

@media only screen and (max-width: 666px) {
  .features__list {
    display: block;
    margin: 0;
  }
}

.features__item {
  flex: 0 0 calc(33.333% - 30px);
  width: calc(33.333% - 30px);
  margin: 96px 15px 0;
}

@media only screen and (max-width: 1199px) {
  .features__item {
    margin-top: 76px;
  }
}

@media only screen and (max-width: 1023px) {
  .features__item {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
  }
}

@media only screen and (max-width: 666px) {
  .features__item {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 666px) {
  .features__item:not(:last-child) {
    margin-bottom: 64px;
  }
}

.features__preview {
  margin-bottom: 35px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .features__preview {
    margin-bottom: 25px;
  }
}

.features__pic {
  height: 72px;
}

.features__category {
  margin-bottom: 16px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 1.125;
  letter-spacing: 1px;
  color: #373981;
}

.review {
  position: relative;
  padding: 142px 0 128px;
  background-image: linear-gradient(0deg, #FFFFFF 0%, #F5F7F8 100%);
  text-align: center;
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .review {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .review__center {
    max-width: 414px;
  }
}

.review__head {
  margin-bottom: 110px;
}

@media only screen and (max-width: 1199px) {
  .review__head {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .review__head {
    margin-bottom: 64px;
  }
}

.review__title {
  margin-bottom: 5px;
  color: #373981;
}

.review__container {
  max-width: 465px;
  margin: 0 auto;
  padding-left: 140px;
}

@media only screen and (max-width: 767px) {
  .review__container {
    padding: 0;
  }
}

.review__ava {
  position: relative;
  width: 336px;
  height: 336px;
  transform: scale(0.71);
  border-radius: 50%;
  margin: 0 auto 168px;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  font-size: 0;
  transition: transform .35s;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .review__ava {
    width: 250px;
    height: 250px;
    margin-bottom: 85px;
    transform: scale(1);
  }
}

.review__ava:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scale(0.5);
  width: 430px;
  height: 430px;
  border: 0.5px solid rgba(134, 142, 156, 0.2);
  border-radius: 50%;
  transition: transform .35s;
}

@media only screen and (max-width: 767px) {
  .review__ava:before {
    top: 1px;
    width: 300px;
    height: 300px;
  }
}

.review__pic {
  position: relative;
  z-index: 2;
  min-height: 100%;
  border-radius: 50%;
}

.review__author {
  margin-bottom: 24px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 1.125;
  letter-spacing: 1px;
  color: #373981;
  opacity: 0;
  transition: opacity .35s;
}

@media only screen and (max-width: 767px) {
  .review__author {
    margin-bottom: 16px;
  }
}

.review__text {
  margin: 0 -120px;
  font-size: 24px;
  line-height: 1.375;
  font-weight: 300;
  font-style: italic;
  opacity: 0;
  transition: opacity .35s;
}

@media only screen and (max-width: 767px) {
  .review__text {
    margin: 0;
    font-size: 16px;
  }
}

.review .owl-item {
  transition: transform .35s;
}

.review .owl-item.active {
  transform: translateX(-75px);
}

@media only screen and (max-width: 767px) {
  .review .owl-item.active {
    transform: translateX(0);
  }
}

.review .owl-item.active .review__ava {
  transform: scale(1.1);
}

@media only screen and (max-width: 767px) {
  .review .owl-item.active .review__ava {
    transform: scale(1);
  }
}

.review .owl-item.active .review__ava:before {
  transform: translateX(-50%) scale(1);
}

.review .owl-item.active .review__author,
.review .owl-item.active .review__text {
  opacity: 1;
}

.review .owl-item.left {
  transform: translateX(-150px);
}

@media only screen and (max-width: 767px) {
  .review .owl-item.left {
    transform: translateX(0);
  }
}

.review .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.review .owl-dots {
  display: flex;
  justify-content: center;
  margin-top: 48px;
  padding-right: 140px;
}

@media only screen and (max-width: 767px) {
  .review .owl-dots {
    margin-top: 32px;
    padding: 0;
  }
}

.review .owl-carousel button.owl-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(134, 142, 156, 0.2);
  transition: background .25s;
}

.review .owl-carousel button.owl-dot:hover {
  background: rgba(134, 142, 156, 0.6);
}

.review .owl-carousel button.owl-dot.active {
  background: #868E9C;
}

.review .owl-carousel button.owl-dot:not(:last-child) {
  margin-right: 16px;
}

.download {
  color: #fff;
}

.download__body {
  position: relative;
  padding: 136px 0 128px;
  overflow: hidden;
  background-image: linear-gradient(138deg, #6049FF 0%, #FDB44D 100%);
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .download__body {
    padding: 100px 0;
  }
}

.download__center {
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .download__center {
    max-width: 414px;
  }
}

.download__title {
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .download__title {
    margin-bottom: 16px;
  }
}

.download__info {
  margin-bottom: 48px;
  opacity: .7;
}

.download__btns {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .download__btns {
    display: block;
  }
}

.download__btn:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .download__btn:not(:last-child) {
    margin: 0 0 20px;
  }
}

.download__btn[data-aos="animation-custom"] {
  transform: translateY(30px) scaleY(1.2);
  opacity: 0;
  transition-property: transform, opacity;
}

.download__btn[data-aos="animation-custom"].aos-animate {
  transform: translateY(0) scale(1);
  opacity: 1;
}

.download__btn .btn {
  min-width: 350px;
  height: 72px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.15);
  font-size: 18px;
  letter-spacing: 1.5px;
}

@media only screen and (max-width: 1023px) {
  .download__btn .btn {
    min-width: 320px;
    height: 60px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .download__btn .btn {
    min-width: 100%;
    height: 46px;
  }
}

.download__circles {
  opacity: .3;
}

.download__circle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background-image: radial-gradient(50% 99%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0.3) 99%);
}

@media only screen and (max-width: 767px) {
  .download__circle {
    top: -115px;
  }
}

.download__circle:first-child {
  bottom: 111px;
  z-index: 3;
  width: 1066px;
  height: 1066px;
}

@media only screen and (max-width: 1199px) {
  .download__circle:first-child {
    width: 900px;
    height: 900px;
  }
}

@media only screen and (max-width: 767px) {
  .download__circle:first-child {
    bottom: auto;
    width: 230px;
    height: 230px;
  }
}

.download__circle:nth-child(2) {
  bottom: -155px;
  z-index: 2;
  width: 1333px;
  height: 1333px;
}

@media only screen and (max-width: 1199px) {
  .download__circle:nth-child(2) {
    bottom: 0;
    width: 1100px;
    height: 1100px;
  }
}

@media only screen and (max-width: 767px) {
  .download__circle:nth-child(2) {
    bottom: auto;
    width: 290px;
    height: 290px;
  }
}

.download__circle:nth-child(3) {
  bottom: -422px;
  z-index: 1;
  width: 1600px;
  height: 1600px;
}

@media only screen and (max-width: 1199px) {
  .download__circle:nth-child(3) {
    bottom: -110px;
    width: 1300px;
    height: 1300px;
  }
}

@media only screen and (max-width: 767px) {
  .download__circle:nth-child(3) {
    bottom: auto;
    width: 350px;
    height: 350px;
  }
}

.download__circle[data-aos="animation-custom"] {
  transform: translate(-50%, -100px) scale(0.8);
  opacity: 0;
  transition-property: transform, opacity;
}

.download__circle[data-aos="animation-custom"].aos-animate {
  transform: translate(-50%, 0) scale(1);
  opacity: 1;
}

.download__list {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .download__list {
    display: block;
  }
}

.download__item {
  display: flex;
  align-items: center;
  flex: 0 0 33.333%;
  width: 33.333%;
  padding: 56px 6.5%;
}

@media only screen and (max-width: 1599px) {
  .download__item {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (max-width: 1365px) {
  .download__item {
    padding: 44px 35px;
  }
}

@media only screen and (max-width: 1199px) {
  .download__item {
    padding: 32px 24px;
  }
}

@media only screen and (max-width: 1023px) {
  .download__item {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .download__item {
    width: 100%;
    padding: 30px;
  }
}

.download__item:first-child {
  background: #373981;
}

.download__item:nth-child(2) {
  background: #4749A3;
}

.download__item:nth-child(3) {
  background: #5B5EC0;
}

@media only screen and (max-width: 1365px) {
  .download__item .download__title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1023px) {
  .download__item .download__title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .download__item .download__title {
    margin-bottom: 10px;
  }
}

.download__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 128px;
  height: 128px;
  margin-right: 32px;
  font-size: 80px;
  line-height: 1;
}

@media only screen and (max-width: 1365px) {
  .download__icon {
    width: 100px;
    height: 100px;
    font-size: 64px;
  }
}

@media only screen and (max-width: 1199px) {
  .download__icon {
    width: 80px;
    height: 80px;
    font-size: 50px;
  }
}

@media only screen and (max-width: 1023px) {
  .download__icon {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .download__icon {
    width: 72px;
    height: 72px;
    margin-right: 25px;
  }
}

.download__icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../img/spinner.png") no-repeat 0 0/100% 100%;
  opacity: .3;
}

.download__note {
  opacity: .7;
}

@media only screen and (max-width: 1023px) {
  .download__note {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .download__note {
    font-size: 16px;
  }
}

.social {
  display: flex;
  align-items: center;
}

.social__link {
  padding: 12px;
  font-size: 0;
  transition: transform .25s;
}

.social__link .icon {
  font-size: 16px;
  fill: #fff;
}

.social__link .icon-twitter {
  font-size: 14px;
}

.social__link:hover {
  transform: translateY(-2px);
}

.footer {
  padding: 64px 0;
  background: #24254B;
}

@media only screen and (max-width: 1199px) {
  .footer {
    padding: 50px 0;
  }
}

.footer__center {
  max-width: 1790px;
}

.footer__row {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1023px) {
  .footer__row {
    display: block;
    text-align: center;
    margin-bottom: 48px;
  }
}

.footer__logo {
  display: inline-block;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .footer__logo {
    margin-bottom: 48px;
  }
}

.footer__logo .footer__pic {
  width: 174px;
}

@media only screen and (max-width: 767px) {
  .footer__logo .footer__pic {
    width: 160px;
  }
}

.footer__menu {
  display: flex;
  margin: 0 auto;
}

@media only screen and (max-width: 1023px) {
  .footer__menu {
    justify-content: center;
    margin: 0 0 48px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__menu {
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
  }
}

.footer__link {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  opacity: .4;
  transition: opacity .25s;
}

@media only screen and (max-width: 1199px) {
  .footer__link {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__link {
    font-size: 16px;
    opacity: .7;
  }
}

.footer__link:hover {
  opacity: 1;
}

.footer__link:not(:last-child) {
  margin-right: 64px;
}

@media only screen and (max-width: 1365px) {
  .footer__link:not(:last-child) {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .footer__link:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__link:not(:last-child) {
    margin: 0 0 16px;
  }
}

.footer__social {
  margin: 0 -12px 0 20px;
}

@media only screen and (max-width: 1023px) {
  .footer__social {
    justify-content: center;
    margin: 0;
  }
}

.footer__copyright {
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}

[data-aos="animation-scale-x"] {
  transform: translateY(40px) scaleY(1.3);
  opacity: 0;
  transition-property: transform, opacity;
}

[data-aos="animation-scale-x"].aos-animate {
  transform: translateY(0) scaleY(1);
  opacity: 1;
}
