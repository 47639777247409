.icon-arrow-right {
  width: 1.91em;
  height: 1em;
  fill: #FFF;
}
.icon-dribbble {
  width: 1em;
  height: 1em;
  fill: #FFF;
}
.icon-facebook {
  width: 0.63em;
  height: 1em;
  fill: #FFF;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: #FFF;
}
.icon-mail {
  width: 1.2em;
  height: 1em;
  fill: #FFF;
}
.icon-twitter {
  width: 1.14em;
  height: 1em;
  fill: #FFF;
}
