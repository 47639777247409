@mixin w {
  @media only screen and (max-width: "1439px") {
    @content; } }
@mixin x {
  @media only screen and (max-width: "1365px") {
    @content; } }
@mixin d {
  @media only screen and (max-width: "1199px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin a {
  @media only screen and (max-width: "666px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,300&family=Poppins&display=swap');
@mixin fo {
  font-family: 'Open Sans', sans-serif; }
@mixin fp {
  font-family: 'Poppins', sans-serif; }

$wh: #fff;
$bl: #000;
$gray: #868E9C;

html {
  // height: 100%
  &.no-scroll {
    overflow: hidden; } }

body {
  min-width: 375px;
  @include fo;
  font-size: 20px;
  line-height: (27/20);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $gray;
  @include d {
    font-size: 18px; }
  @include m {
    font-size: 16px; }
  &.no-scroll {
    overflow: hidden; } }

.page {
  overflow: hidden; }

a.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center; }

.btn {
  min-width: 270px;
  height: 48px;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.20);
  @include fo;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all .25s;
  @include m {
    height: 42px; }
  &__pic,
  &__text {
    display: inline-block;
    vertical-align: middle; }
  &__pic {
    margin-right: 17px;
    @include m {
      max-height: 24px; } }
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 20px 0 rgba(0,0,0,0.25); }
  &:active {
    transform: translateY(2px);
    box-shadow: 0 3px 7px 0 rgba(0,0,0,0.15); }
  &_blue {
    background: #373981;
    color: $wh; }
  &_white {
    background: $wh;
    color: #8B5DF2; } }

.center {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px; }

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 48px 0;
  @include d {
    padding: 36px 0; }
  @include m {
    padding: 26px 0; }
  &__center {
    display: flex;
    align-items: center;
    max-width: 1790px; }
  &__logo {
    font-size: 0;
    @include d {
      margin-top: -5px; }
    @include t {
      margin: 0 auto 0 0; }
    @include m {
      position: relative;
      z-index: 20; } }
  &__logo &__pic {
    width: 174px;
    @include m {
      width: 160px; } }
  &__wrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
    @include t {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      width: 350px;
      min-height: 100vh;
      padding: 100px 50px 50px;
      background: #373981;
      transform: translateX(100%);
      transition: transform .25s;
      &.visible {
        transform: translateX(0); } }
    @include m {
      width: 100%;
      padding-top: 120px;
      background: rgba(#373981, .95);
      text-align: center; } }
  &__nav {
    display: flex;
    margin: 0 auto;
    @include t {
      flex-direction: column;
      margin-bottom: 50px; }
    @include m {
      align-items: center;
      margin-bottom: 20px; }
    @include s {
      margin-bottom: 40px; } }
  &__link {
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $wh;
    opacity: .4;
    transition: opacity .25s;
    @include d {
      font-size: 15px;
      opacity: .7; }
    @include t {
      font-size: 20px;
      font-weight: 700;
      opacity: 1; }
    &:hover {
      opacity: 1; }
    @include nl {
      margin-right: 64px;
      @include x {
        margin-right: 40px; }
      @include d {
        margin-right: 20px; }
      @include t {
        margin: 0 0 50px; }
      @include m {
        margin-bottom: 20px; }
      @include s {
        margin-bottom: 40px; } } }
  &__contact {
    display: inline-flex;
    align-items: center;
    margin-left: 35px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    color: $wh;
    transition: opacity .25s;
    @include d {
      margin-left: 0; }
    @include t {
      display: inline-block;
      font-size: 20px; }
    .icon {
      margin-right: 15px;
      font-size: 20px;
      fill: $wh;
      @include t {
        display: none; } }
    &:hover {
      opacity: .75; } }
  &__burger {
    display: none;
    position: relative;
    z-index: 12;
    width: 30px;
    height: 30px;
    background: none;
    font-size: 0;
    transition: opacity .25s;
    @include t {
      display: inline-block; }
    &:before,
    &:after {
      content: "";
      display: inline-block;
      width: 30px;
      height: 3px;
      background: $wh;
      border-radius: 2px;
      transition: transform .25s;
      @include m {
        width: 22px;
        height: 2px; } }
    &:before {
      margin-bottom: 4px;
      @include m {
        margin-bottom: 3px; } }
    &:after {
      margin-top: 4px;
      @include m {
        margin-top: 3px; } }
    &:hover {
      opacity: .85; }
    &.active {
      &:before {
        transform: translateY(5.5px) rotate(45deg);
        @include m {
          transform: translateY(4px) rotate(45deg); } }
      &:after {
        transform: translateY(-5.5px) rotate(-45deg);
        @include m {
          transform: translateY(-4px) rotate(-45deg); } } } }
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 8;
    background: rgba($bl, .7);
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    &.show {
      @include t {
        visibility: visible;
        opacity: 1; } } } }

.main {
  position: relative;
  padding: 263px 0 128px;
  background-image: linear-gradient(46deg, #7040DC 0%, #C37BFB 53%, #F99F1E 100%);
  color: $wh;
  @include w {
    padding-top: 240px; }
  @include d {
    padding-top: 210px; }
  @include m {
    padding: 120px 0 450px; }
  &__center {
    position: relative;
    z-index: 5; }
  &__title {
    max-width: 470px;
    margin-bottom: 25px; }
  &__text {
    max-width: 470px;
    margin-bottom: 64px;
    opacity: .7;
    @include m {
      margin-bottom: 34px; } }
  &__btns {
    display: flex;
    @include a {
      display: block; } }
  &__btn {
    @include nl {
      margin-right: 30px;
      @include a {
        margin: 0 0 20px; } }
    .btn {
      @include t {
        min-width: 240px; }
      @include a {
        min-width: 100%; } } }
  &__preview {
    position: absolute;
    right: calc(50% - 784px);
    bottom: -145px;
    z-index: 4;
    font-size: 0;
    pointer-events: none;
    opacity: 0;
    animation: phone 1.2s .7s forwards;
    @include x {
      right: calc(50% - 670px);
      bottom: -117px; }
    @include d {
      right: calc(50% - 620px); }
    @include t {
      right: calc(50% - 575px);
      bottom: -52px; }
    @include m {
      right: 20%;
      bottom: -120px; }
    @include a {
      right: -20%; } }
  &__preview &__pic {
    @include x {
      width: 650px; }
    @include d {
      width: 600px; }
    @include t {
      width: 550px; }
    @include m {
      width: 450px; } }
  &__circles {
    @include coverdiv;
    overflow: hidden;
    opacity: .3; }
  &__circle {
    position: absolute;
    top: -160px;
    border-radius: 50%;
    background-image: radial-gradient(50% 99%, rgba(255,255,255,0.00) 51%, rgba(255,255,255,0.30) 99%);
    animation: circle1 1s forwards;
    opacity: 0;
    @include m {
      top: -70px; }
    &:first-child {
      right: calc(50% - 1080px);
      z-index: 3;
      width: 800px;
      height: 800px;
      animation-delay: .3s;
      @include d {
        right: calc(50% - 980px);
        width: 700px;
        height: 700px; }
      @include m {
        right: -125px;
        width: 250px;
        height: 250px; } }
    &:nth-child(2) {
      right: calc(50% - 1180px);
      z-index: 2;
      width: 1000px;
      height: 1000px;
      animation-delay: .6s;
      @include d {
        right: calc(50% - 1080px);
        width: 900px;
        height: 900px; }
      @include m {
        right: -150px;
        width: 300px;
        height: 300px; } }
    &:nth-child(3) {
      right: calc(50% - 1280px);
      z-index: 1;
      width: 1200px;
      height: 1200px;
      animation-delay: .9s;
      @include d {
        right: calc(50% - 1180px);
        width: 1100px;
        height: 1100px; }
      @include m {
        right: -175px;
        width: 350px;
        height: 350px; } } } }

@keyframes phone {
  0% {
    transform: translateX(20%) rotate(20deg);
    opacity: 0; }
  30% {
    opacity: .7; }
  70% {
    opacity: 1; }
  100% {
    transform: translateX(0) rotate(0);
    opacity: 1; } }

@keyframes circle1 {
  0% {
    transform: translate(10%,-20%);
    opacity: 0; }
  100% {
    transform: translate(0,0);
    opacity: 1; } }

.clients {
  padding: 64px 0;
  background: #373981;
  @include t {
    padding: 50px 0; }
  @include m {
    padding: 96px 0; }
  &__title {
    margin-bottom: 39px;
    text-align: center;
    @include fp;
    font-size: 24px;
    line-height: (33/24);
    letter-spacing: 1px;
    color: $wh;
    @include m {
      font-size: 22px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -25px; }
  &__logo {
    margin: 25px 25px 0;
    font-size: 0;
    @include m {
      flex: 0 0 50%;
      width: 50%;
      margin: 25px 0 0;
      padding: 0 15px;
      text-align: center; }
    &[data-aos="animation-custom"] {
      transform: translateY(30px) scaleY(2);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
        transform: translateY(0) scaleY(1);
        opacity: 1; } } }
  &__pic {
    @include m {
      max-width: 100%; } } }

.title {
  @include fp;
  font-size: 40px;
  line-height: (50/40);
  letter-spacing: 1px;
  @include d {
    font-size: 32px; }
  @include m {
    font-size: 26px; }
  &_big {
    font-size: 60px;
    line-height: (70/60);
    @include d {
      font-size: 50px; }
    @include m {
      font-size: 34px; } } }

.details {
  padding: 178px 0;
  background-image: linear-gradient(180deg, #373881 0%, #8B84E7 51%, #FFA350 100%);
  @include x {
    padding: 130px 0; }
  @include d {
    padding: 100px 0; }
  @include m {
    padding-top: 70px; }
  &__center {
    @include d {
      max-width: 1024px; }
    @include t {
      max-width: 768px; }
    @include m {
      max-width: 375px; } }
  &__item {
    display: flex;
    align-items: center;
    @include t {
      flex-direction: column; }
    &:nth-child(2n+1) {
      .details__preview {
        margin-right: auto;
        margin-left: 200px;
        @include d {
          margin-left: 150px; }
        @include t {
          margin: 0 auto 50px 250px; }
        @include m {
          margin: 0 0 55px auto; } } }
    &:nth-child(2n) {
      @include t {
        flex-direction: column-reverse; }
      .details__preview {
        margin-left: auto;
        @include t {
          margin: 0 auto 55px 250px; }
        @include m {
          margin: 0 0 55px auto; } } }
    @include nl {
      margin-bottom: 228px;
      @include x {
        margin-bottom: 180px; }
      @include d {
        margin-bottom: 140px; }
      @include t {
        margin-bottom: 120px; } } }
  &__preview {
    position: relative;
    text-align: right;
    font-size: 0;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 470px;
      height: 470px;
      border-radius: 50%;
      border: .5px solid rgba($wh, .4);
      @include d {
        width: 390px;
        height: 390px; }
      @include m {
        width: 270px;
        height: 270px; } } }
  &__photo {
    display: inline-block;
    position: relative;
    z-index: 2;
    width: 370px;
    height: 370px;
    border-radius: 50%;
    box-shadow: 0 20px 20px 0 rgba(0,0,0,0.15);
    @include d {
      width: 320px;
      height: 320px; }
    @include m {
      width: 220px;
      height: 220px; } }
  &__bg {
    position: absolute;
    bottom: 33px;
    right: 300px;
    width: 270px;
    z-index: 1;
    @include d {
      right: 250px;
      width: 230px; }
    @include m {
      right: 175px;
      width: 150px; }
    &[data-aos="animation-custom"] {
      transform: translateX(50px);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
        transform: translateX(0);
        opacity: 1; } } }
  &__pic {
    width: 100%;
    border-radius: 50%; }
  &__number {
    position: absolute;
    top: -50px;
    right: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    padding-top: 5px;
    overflow: hidden;
    background: $wh;
    border-radius: 50%;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.10);
    @include fp;
    font-size: 40px;
    color: #8B5DF2;
    @include d {
      top: -40px;
      width: 90px;
      height: 90px;
      font-size: 36px; }
    @include m {
      top: -25px;
      width: 64px;
      height: 64px;
      font-size: 28px; }
    &[data-aos="animation-custom"] {
      transform: translateY(50px) scaleY(1.1);
      transition-property: transform, opacity;
      opacity: 0;
      &.aos-animate {
        transform: translateY(0) scaleY(1);
        opacity: 1; } } }
  &__wrap {
    width: 470px;
    padding-top: 8px;
    color: $wh;
    @include d {
      width: 410px; }
    @include t {
      width: 100%; } }
  &__title {
    margin-bottom: 20px; }
  &__text {
    margin-bottom: 42px;
    opacity: 0.7;
    @include d {
      margin-bottom: 32px; } }
  &__more {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $wh;
    .icon {
      margin-left: 8px;
      font-size: 10px;
      fill: $wh;
      transition: transform .25s; }
    &:hover .icon {
      transform: translateX(3px); } } }

.features {
  padding: 141px 0 128px;
  text-align: center;
  @include d {
    padding: 100px 0; }
  &__title {
    margin-bottom: 5px;
    color: #373981;
    @include m {
      margin-bottom: 16px; } }
  &__info {
    margin-bottom: 96px;
    @include d {
      margin-bottom: 76px; }
    @include m {
      margin-bottom: 64px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -96px -15px 0;
    @include d {
      margin-top: -76px; }
    @include a {
      display: block;
      margin: 0; } }
  &__item {
    flex: 0 0 calc(33.333% - 30px);
    width: calc(33.333% - 30px);
    margin: 96px 15px 0;
    @include d {
      margin-top: 76px; }
    @include t {
      flex: 0 0 calc(50% - 30px);
      width: calc(50% - 30px); }
    @include a {
      width: 100%;
      margin: 0; }
    @include nl {
      @include a {
        margin-bottom: 64px; } } }
  &__preview {
    margin-bottom: 35px;
    font-size: 0;
    @include m {
      margin-bottom: 25px; } }
  &__pic {
    height: 72px; }
  &__category {
    margin-bottom: 16px;
    @include fp;
    font-size: 24px;
    line-height: (27/24);
    letter-spacing: 1px;
    color: #373981; } }

.review {
  position: relative;
  padding: 142px 0 128px;
  background-image: linear-gradient(0deg, #FFFFFF 0%, #F5F7F8 100%);
  text-align: center;
  overflow: hidden;
  @include d {
    padding: 100px 0; }
  &__center {
    @include m {
      max-width: 414px; } }
  &__head {
    margin-bottom: 110px;
    @include d {
      margin-bottom: 80px; }
    @include m {
      margin-bottom: 64px; } }
  &__title {
    margin-bottom: 5px;
    color: #373981; }
  &__container {
    max-width: 465px;
    margin: 0 auto;
    padding-left: 140px;
    @include m {
      padding: 0; } }
  &__ava {
    position: relative;
    width: 336px;
    height: 336px;
    transform: scale(.71);
    border-radius: 50%;
    margin: 0 auto 168px;
    box-shadow: 0 20px 20px 0 rgba(0,0,0,0.15);
    text-align: center;
    font-size: 0;
    transition: transform .35s;
    cursor: pointer;
    @include m {
      width: 250px;
      height: 250px;
      margin-bottom: 85px;
      transform: scale(1); }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) scale(.5);
      width: 430px;
      height: 430px;
      border: 0.5px solid rgba($gray, .2);
      border-radius: 50%;
      transition: transform .35s;
      @include m {
        top: 1px;
        width: 300px;
        height: 300px; } } }
  &__pic {
    position: relative;
    z-index: 2;
    min-height: 100%;
    border-radius: 50%; }
  &__author {
    margin-bottom: 24px;
    @include fp;
    font-size: 24px;
    line-height: (27/24);
    letter-spacing: 1px;
    color: #373981;
    opacity: 0;
    transition: opacity .35s;
    @include m {
      margin-bottom: 16px; } }
  &__text {
    margin: 0 -120px;
    font-size: 24px;
    line-height: (33/24);
    font-weight: 300;
    font-style: italic;
    opacity: 0;
    transition: opacity .35s;
    @include m {
      margin: 0;
      font-size: 16px; } }
  .owl-item {
    transition: transform .35s; }
  .owl-item {
    &.active {
      transform: translateX(-75px);
      @include m {
        transform: translateX(0); }
      .review__ava {
        transform: scale(1.1);
        @include m {
          transform: scale(1); }
        &:before {
          transform: translateX(-50%) scale(1); } }
      .review__author,
      .review__text {
        opacity: 1; } }
    &.left {
      transform: translateX(-150px);
      @include m {
        transform: translateX(0); } } }
  .owl-carousel .owl-stage-outer {
    overflow: visible; }
  .owl-dots {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    padding-right: 140px;
    @include m {
      margin-top: 32px;
      padding: 0; } }
  .owl-carousel button.owl-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba($gray, .2);
    transition: background .25s;
    &:hover {
      background: rgba($gray, .6); }
    &.active {
      background: $gray; }
    @include nl {
      margin-right: 16px; } } }


.download {
  color: $wh;
  &__body {
    position: relative;
    padding: 136px 0 128px;
    overflow: hidden;
    background-image: linear-gradient(138deg, #6049FF 0%, #FDB44D 100%);
    text-align: center;
    @include d {
      padding: 100px 0; } }
  &__center {
    position: relative;
    z-index: 5;
    @include m {
      max-width: 414px; } }
  &__title {
    margin-bottom: 10px;
    @include m {
      margin-bottom: 16px; } }
  &__info {
    margin-bottom: 48px;
    opacity: .7; }
  &__btns {
    display: flex;
    justify-content: center;
    @include m {
      display: block; } }
  &__btn {
    @include nl {
      margin-right: 30px;
      @include m {
        margin: 0 0 20px; } }
    &[data-aos="animation-custom"] {
      transform: translateY(30px) scaleY(1.2);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
        transform: translateY(0) scale(1);
        opacity: 1; } }
    .btn {
      min-width: 350px;
      height: 72px;
      box-shadow: 0 6px 10px 0 rgba(0,0,0,0.15);
      font-size: 18px;
      letter-spacing: 1.5px;
      @include t {
        min-width: 320px;
        height: 60px;
        font-size: 16px; }
      @include m {
        min-width: 100%;
        height: 46px; } } }
  &__circles {
    opacity: .3; }
  &__circle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background-image: radial-gradient(50% 99%, rgba(255,255,255,0.00) 51%, rgba(255,255,255,0.30) 99%);
    @include m {
      top: -115px; }
    &:first-child {
      bottom: 111px;
      z-index: 3;
      width: 1066px;
      height: 1066px;
      @include d {
        width: 900px;
        height: 900px; }
      @include m {
        bottom: auto;
        width: 230px;
        height: 230px; } }
    &:nth-child(2) {
      bottom: -155px;
      z-index: 2;
      width: 1333px;
      height: 1333px;
      @include d {
        bottom: 0;
        width: 1100px;
        height: 1100px; }
      @include m {
        bottom: auto;
        width: 290px;
        height: 290px; } }
    &:nth-child(3) {
      bottom: -422px;
      z-index: 1;
      width: 1600px;
      height: 1600px;
      @include d {
        bottom: -110px;
        width: 1300px;
        height: 1300px; }
      @include m {
        bottom: auto;
        width: 350px;
        height: 350px; } }
    &[data-aos="animation-custom"] {
      transform: translate(-50%,-100px) scale(.8);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
        transform: translate(-50%,0) scale(1);
        opacity: 1; } } }
  &__list {
    display: flex;
    @include m {
      display: block; } }
  &__item {
    display: flex;
    align-items: center;
    flex: 0 0 33.333%;
    width: 33.333%;
    padding: 56px 6.5%;
    @include r(1599) {
      padding-left: 50px;
      padding-right: 50px; }
    @include x {
      padding: 44px 35px; }
    @include d {
      padding: 32px 24px; }
    @include t {
      padding: 20px; }
    @include m {
      width: 100%;
      padding: 30px; }
    &:first-child {
      background: #373981; }
    &:nth-child(2) {
      background: #4749A3; }
    &:nth-child(3) {
      background: #5B5EC0; } }
  &__item &__title {
    @include x {
      font-size: 50px; }
    @include t {
      font-size: 32px; }
    @include m {
      margin-bottom: 10px; } }
  &__icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 128px;
    height: 128px;
    margin-right: 32px;
    font-size: 80px;
    line-height: 1;
    @include x {
      width: 100px;
      height: 100px;
      font-size: 64px; }
    @include d {
      width: 80px;
      height: 80px;
      font-size: 50px; }
    @include t {
      width: 60px;
      height: 60px;
      margin-right: 20px;
      font-size: 40px; }
    @include m {
      width: 72px;
      height: 72px;
      margin-right: 25px; }
    &:before {
      content: "";
      @include coverdiv;
      background: url('../img/spinner.png') no-repeat 0 0 / 100% 100%;
      opacity: .3; } }
  &__note {
    opacity: .7;
    @include t {
      font-size: 14px; }
    @include m {
      font-size: 16px; } } }

.social {
  display: flex;
  align-items: center;
  &__link {
    padding: 12px;
    font-size: 0;
    transition: transform .25s;
    .icon {
      font-size: 16px;
      fill: $wh; }
    .icon-twitter {
      font-size: 14px; }
    &:hover {
      transform: translateY(-2px); } } }

.footer {
  padding: 64px 0;
  background: #24254B;
  @include d {
    padding: 50px 0; }
  &__center {
    max-width: 1790px; }
  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    @include t {
      display: block;
      text-align: center;
      margin-bottom: 48px; } }
  &__logo {
    display: inline-block;
    font-size: 0;
    @include t {
      margin-bottom: 48px; } }
  &__logo &__pic {
    width: 174px;
    @include m {
      width: 160px; } }
  &__menu {
    display: flex;
    margin: 0 auto;
    @include t {
      justify-content: center;
      margin: 0 0 48px; }
    @include m {
      flex-direction: column;
      align-items: center;
      justify-content: stretch; } }
  &__link {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $wh;
    opacity: .4;
    transition: opacity .25s;
    @include d {
      font-size: 15px; }
    @include m {
      font-size: 16px;
      opacity: .7; }
    &:hover {
      opacity: 1; }
    @include nl {
      margin-right: 64px;
      @include x {
        margin-right: 40px; }
      @include d {
        margin-right: 20px; }
      @include m {
        margin: 0 0 16px; } } }
  &__social {
    margin: 0 -12px 0 20px;
    @include t {
      justify-content: center;
      margin: 0; } }
  &__copyright {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: rgba($wh, .5); } }

[data-aos="animation-scale-x"] {
  transform: translateY(40px) scaleY(1.3);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0) scaleY(1);
    opacity: 1; } }



